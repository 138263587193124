import { PageContainer } from '@ant-design/pro-layout';
import { Space, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useAppDispatch } from 'store';
import { useFetchUser, useMutate } from 'hooks';
import useFetchList from 'hooks/useList';
import skillsQuery from 'models/skills/query';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import LanguageForm from 'components/skills/LanguageForm';
import LanguageList from 'components/skills/LanguageList';
import Helper from 'utils/helpers';
import { ICertInitValue, IListLanguage } from 'models/skills/interface';
import LanguageCertificationsForm from 'components/skills/LanguageCertificationForm';
import LanguageCertificationsList from 'components/skills/LanguageCertificationList';

const { Title } = Typography;

const LanguagesPage = (): JSX.Element => {
  const { t } = useTranslation();
  const header = {
    title: t('menuBar:languages'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:skills')} / ${t('menuBar:languages')}`,
        },
      ],
    },
  };

  const dispatch = useAppDispatch();

  // state
  const [initialValueForm, setInitialValueForm] = useState({});
  const [userCertification, setUserCertification] = useState<any>({});

  const { data } = useFetchUser({ enabled: true });
  const {
    list: dataList,
    isLoading: fetchData,
    refetch: refetchList,
  } = useFetchList<IListLanguage>({
    ...skillsQuery.languageList,
    customParams: {
      ...skillsQuery.languageList.customParams,
      userId: _get(data, '_id'),
    },
  });

  const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useMutate<{
    languageExperience: string;
  }>(skillsQuery.deleteLanguage);
  // certification language
  const {
    list: certificationList,
    isLoading: fetchingCert,
    refetch: refetchCertificationList,
  } = useFetchList<ICertInitValue>(skillsQuery.languageCertificationList);
  const { mutateAsync: deleteCertification, isLoading: isLoadingDelCert } = useMutate<{
    certificationId: string;
  }>(skillsQuery.deleteCertification);

  // scroll to top form section when edit
  const handleScrollLanguageForm = () => {
    const element = document.querySelector('.language-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollLanguageCertificate = () => {
    const element = document.querySelector('.language-cert-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handLeDeleteItem = (id: string) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteItem(
              { languageExperience: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  resetInitialValue();
                  refetchList();
                },
                onError: (data) => {
                  Helper.toasts('', _get(data, 'message', ''), 'error');
                },
              },
            );
          },
        },
      }),
    );
  };

  const handleEditItem = (item: IListLanguage) => {
    setInitialValueForm(item);
    handleScrollLanguageForm();
  };

  // Edit and delete certification
  const handleEditCertification = (item) => {
    setUserCertification(item);
    handleScrollLanguageCertificate();
  };

  const handleDeleteCertification = (id) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteCertification(
              { certificationId: id },
              {
                onSuccess: () => {
                  refetchCertificationList();
                  resetInitialValueCert();
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                },
                onError: (data) => {
                  Helper.toasts('', _get(data, 'message', ''), 'error');
                },
              },
            );
          },
        },
      }),
    );
  };

  const resetInitialValue = () => {
    setInitialValueForm({});
  };
  const resetInitialValueCert = () => {
    setUserCertification({});
  };

  return (
    <PageContainer header={header}>
      <Space direction="vertical" className="skills-page-wrapper languages-wrapper">
        <div className="language-scroll"></div>
        <Typography style={{ padding: '0 15px 0 28px', fontWeight: 500 }}>
          仕事で使用可能な言語とそのレベル・経験年数を登録しましょう。
        </Typography>
        <div className="green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:languagesTitle')}</Title>
          <LanguageForm
            initialValue={initialValueForm}
            resetInitialValue={resetInitialValue}
            refreshDataList={refetchList}
          />
          {dataList?.length > 0 && (
            <LanguageList
              data={dataList}
              initLoading={fetchData || isLoadingDelete}
              handleEdit={handleEditItem}
              handleDelete={handLeDeleteItem}
            />
          )}
        </div>
        <div className="language-cert-scroll"></div>
        <div className="mt-80 green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:qualificationTitle')}</Title>
          <LanguageCertificationsForm
            resetInitialValue={resetInitialValueCert}
            refreshDataList={refetchCertificationList}
            initialValue={userCertification}
          />
          {certificationList?.length > 0 && (
            <LanguageCertificationsList
              data={certificationList}
              initLoading={fetchingCert || isLoadingDelCert}
              handleEdit={handleEditCertification}
              handleDelete={handleDeleteCertification}
            />
          )}
        </div>
      </Space>
    </PageContainer>
  );
};

export default LanguagesPage;
