/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Select, Form } from 'antd';
import { useController, Control, Path, FieldValues } from 'react-hook-form';
import { SelectProps } from 'antd/lib/select';
import _get from 'lodash/get';
import { InfoCircleOutlined } from '@ant-design/icons';
import './style.less';
import { useState } from 'react';
import HelperText from 'components/Form/HelperTextTooltip';

const { Option } = Select;

interface SelectFieldProps<TFormValues extends FieldValues> extends SelectProps {
  label?: string;
  noLabel?: boolean;
  className?: string;
  placeholder?: string;
  required?: boolean;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  data?: { _id: string | number; name: string }[];
  helperText?: string;
  maxLength?: number;
  loading?: boolean;
}

const SelectField = <TFormValues extends FieldValues>({
  label,
  noLabel,
  maxLength,
  data = [],
  className = '',
  placeholder = 'None',
  name,
  control,
  required,
  helperText,
  allowClear,
  loading,
  ...props
}: SelectFieldProps<TFormValues>) => {
  const {
    field: { value, onChange, onBlur, ...otherField },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const isError = error;
  const [isFocus, setIsFocus] = useState(false);
  const handleOnBlur = () => {
    setIsFocus(false);
    onBlur();
  };
  const handleOnchange = (value) => {
    if (value === undefined) {
      onChange('');
    } else {
      onChange(value);
    }
  };

  const handleRemoveAttrTitle = (e) => {
    const element = e.target;
    let childElement = element.getElementsByClassName('ant-select-selection-item');
    if (element.className === 'ant-select-selector') {
      childElement = element.getElementsByClassName('ant-select-selection-item');
    } else {
      if (element.nodeName === 'INPUT') {
        childElement = element.parentNode.parentNode.getElementsByClassName(
          'ant-select-selection-item',
        );
      }
    }
    if (childElement.length > 0) {
      for (let i = 0; i < childElement.length; i++) childElement[i].removeAttribute('title');
    }
  };

  return (
    <div className={`select-container select-field-container ${className}`}>
      {label && (
        <div className="label-wrapper">
          {required && !noLabel ? (
            <div className="required-mark">必須</div>
          ) : (
            <div className="not-required-mark">任意</div>
          )}
          <div className={`${isFocus ? 'on-focus-select' : 'label'}`}>{label}</div>
          {helperText && <HelperText text={helperText} />}
        </div>
      )}
      {noLabel && <div className="no-label"> </div>}
      <Form.Item
        validateStatus={isError ? 'error' : 'success'}
        colon={false}
        help={
          <div className="helper-wrapper">
            <div className="error-text">
              {isError && (
                <>
                  <InfoCircleOutlined className="info-icon" type="info-circle" />
                  {error.message}
                </>
              )}
            </div>
            {maxLength && (
              <div className="max-length-text">
                {_get(value, 'length', 0)}/{maxLength}
              </div>
            )}
          </div>
        }
      >
        <Select
          {...props}
          {...otherField}
          allowClear
          placeholder={placeholder}
          value={value === '' ? null : value}
          getPopupContainer={(trigger) => trigger.parentNode}
          onFocus={() => setIsFocus(true)}
          onBlur={handleOnBlur}
          onChange={handleOnchange}
          onMouseEnter={(e) => handleRemoveAttrTitle(e)}
          loading={loading}
        >
          <>
            {data.map((option) => (
              <Option value={option._id} key={option._id} title={null}>
                {option.name}
              </Option>
            ))}
          </>
        </Select>
      </Form.Item>
    </div>
  );
};

export default SelectField;
