import React, { useEffect, useState, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Space } from 'antd';

interface DragAndDropListProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onDragEnd: (items: T[]) => void;
  className?: string;
}

const DragAndDropList = <T,>({
  items,
  renderItem,
  onDragEnd,
  className,
}: DragAndDropListProps<T>) => {
  const [list, setList] = useState<T[]>(items);
  const [itemHeights, setItemHeights] = useState<number[]>([]);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    setList(items);
  }, [items]);

  useEffect(() => {
    const updateHeights = () => {
      const heights = itemRefs.current.map((ref) => ref?.clientHeight || 0);
      setItemHeights(heights);
    };

    updateHeights();
    setTimeout(updateHeights, 0);

    const resizeObserver = new ResizeObserver(updateHeights);
    itemRefs.current.forEach((ref) => {
      if (ref) resizeObserver.observe(ref);
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [list]);

  const onDragEndHandler = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const reorderedList = Array.from(list);
    const [movedItem] = reorderedList.splice(source.index, 1) as [T];
    reorderedList.splice(destination.index, 0, movedItem);

    setList(reorderedList);
    onDragEnd(reorderedList);
  };

  const renderIndexCol = () =>
    list.map((_, index) => (
      <Space
        key={index}
        style={{
          height: itemHeights[index] || 'auto',
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <span className="count-number">{index + 1}</span>
      </Space>
    ));

  return (
    <div className={className}>
      <DragDropContext onDragEnd={onDragEndHandler}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: 'flex', alignItems: 'flex-start', gap: 12 }}
            >
              <Space direction="vertical" size={16}>
                {renderIndexCol()}
              </Space>
              <div style={{ flexGrow: 1 }}>
                {list.map((item, index) => (
                  <Draggable
                    key={(item as any)._id ?? index}
                    draggableId={(item as any)._id ?? index.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={(el) => {
                          provided.innerRef(el);
                          itemRefs.current[index] = el;
                        }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="draggable-content"
                        style={{
                          ...provided.draggableProps.style,
                          margin: '0 0 16px 0',
                          minHeight: '50px',
                          backgroundColor: '#fff',
                          borderRadius: '20px',
                          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                          display: 'inline-block',
                          width: '100%',
                          maxWidth: 348,
                        }}
                      >
                        {renderItem(item)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DragAndDropList;
