import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { Typography, Space, Button, Spin } from 'antd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormModal from 'components/FormModal';
import { setFormModal } from 'providers/GeneralProvider/slice';
import { AVATAR_COLOR } from 'utils/constants';
import authQuery from 'models/auth/query';

import './styles.less';
import { useFetchUser, useMutate } from 'hooks';
import { useAppDispatch } from 'store';
import Helper from 'utils/helpers';

const ChangeAvatarPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: currentUser, refetch } = useFetchUser({ enabled: true });

  const [currentColor, setCurrentColor] = useState<string>();
  const { mutateAsync: updateAvatar, isLoading } = useMutate<{ avatarColor: string }>(
    authQuery.updateAvatarColor,
  );

  useEffect(() => {
    setCurrentColor(_get(currentUser, 'avatarColor'));
  }, [currentUser]);

  const showChangeColorModal = () => {
    dispatch(
      setFormModal({
        key: 'requestChangeColor',
        isOpen: true,
        data: {},
      }),
    );
  };

  const saveAvatarIconColor = () => {
    updateAvatar(
      {
        avatarColor: currentColor || AVATAR_COLOR[AVATAR_COLOR.length - 1],
      },
      {
        onSuccess: () => {
          Helper.toasts('', 'ユーザー情報の更新成功', 'success');
          refetch();
          dispatch(
            setFormModal({
              key: 'requestChangeColor',
              isOpen: false,
              data: {},
            }),
          );
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );
  };

  return (
    <div className="green-opacity-bg inner-content block-01 p-32 avatar-wrapper">
      <div className="avatar-icon" style={{ color: _get(currentUser, 'avatarColor') }}>
        <AccountCircleIcon />
      </div>
      <Button type="primary" className="change-btn" onClick={showChangeColorModal}>
        カラー編集
      </Button>

      <FormModal keyModal="requestChangeColor" title="" className="bg-white">
        <Spin spinning={isLoading}>
          <div className="avatar-wrapper edit-avatar-wrapper">
            <div className="avatar-icon" style={{ color: currentColor }}>
              <AccountCircleIcon />
            </div>
            <div className="inner-edit-wrapper">
              <Typography.Title level={5}>アイコンの色を選択してください</Typography.Title>
              <Space size={10}>
                {AVATAR_COLOR.map((itemColor) => (
                  <button
                    className="color-button"
                    onClick={() => setCurrentColor(itemColor)}
                    style={{ background: itemColor }}
                  />
                ))}
              </Space>
              <Button type="primary" className="change-btn" onClick={saveAvatarIconColor}>
                変更
              </Button>
            </div>
          </div>
        </Spin>
      </FormModal>
    </div>
  );
};

export default ChangeAvatarPage;
