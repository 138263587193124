/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Space, Typography } from 'antd';
import { get } from 'lodash';
import defaultCompany from 'assets/images/default-company.svg';
import GroupIcon from 'assets/images/icon_group_24px.svg';
import LocationIcon from 'assets/images/icon_local_24px.svg';
import AccountIcon from 'assets/images/icon_account_24px.svg';
import PriceIcon from 'assets/images/icon_file.svg';

import { IProject } from 'models/preference/interface';
import './style.less';
import { CHARACTERISTICS, OVERTIME_DAY_VALUES, WORK_STYLE } from 'utils/constants';
import Helper from 'utils/helpers';

const ProjectContent = ({ type, data }: { type: 'small' | 'large'; data: IProject }) => {
  const { project, projectPosition } = data;

  return (
    <div className={`project-content ${type}`}>
      <Space className="project-info" size={12}>
        <img src={data.contractorCompany.profileImage || defaultCompany} alt="自己紹介" />
        <div>
          <Typography.Title
            level={3}
            ellipsis={{
              tooltip: data.project.title,
            }}
          >
            {data.project.title}
          </Typography.Title>
          <Typography.Title
            level={4}
            ellipsis={{
              tooltip: data.projectPosition.positionInformation,
            }}
          >
            {data.projectPosition.positionInformation}
          </Typography.Title>
        </div>
      </Space>
      <Space wrap size={12} className="other-info">
        <Space align="start" size={4}>
          <img src={LocationIcon} alt={'area'} />
          <span>
            {project.prefecture.kanji}
            {project.districtAndCity}
          </span>
        </Space>
        <Space align="start" size={4}>
          <img src={AccountIcon} alt={'work style'} />
          <span>{get(WORK_STYLE, `${project.workStyle}`)}</span>
        </Space>
        {(project?.characteristicsProject || []).map((content: string) => (
          <Space key={content} align="start" size={4}>
            <img src={GroupIcon} alt={content} />
            <span>{get(CHARACTERISTICS, `${content}`)}</span>
          </Space>
        ))}
      </Space>
      <div className="position-info">
        <Typography.Title level={4}>オファーポジション</Typography.Title>
        <div>
          {data.project.title}/{data.projectPosition.positionInformation}/
          {data.projectPosition.positionName}/
          {projectPosition.overtimeDayOffWork &&
            projectPosition.overtimeDayOffWork.map((itemOvertimeDay: string, index: number) => (
              <span key={index}>
                {index > 0 && '・'}
                {get(OVERTIME_DAY_VALUES, `${itemOvertimeDay}`)}
              </span>
            ))}
        </div>
        <Space className="price" size={4}>
          <img src={PriceIcon} alt={'price'} />
          <span>
            {Helper.formatCurrencyJPYName(Number(data.projectPosition.minSalary))}～
            {Helper.formatCurrencyJPYName(Number(data.projectPosition.maxSalary))}
          </span>
        </Space>
      </div>
    </div>
  );
};

export default ProjectContent;
