import { useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
// import { NotificationItem } from 'admin-console-library';
import { useCustomDispatch } from 'hooks';
import { useAppSelector } from 'store';

interface Params {
  page: number;
  limit: number;
  [key: string]: any;
}
interface Options {
  providerName?: string;
}

const useFetchListNotChangeSearchParam = (
  action?: (payload) => AnyAction,
  options?: Options,
): {
  params: Record<string, number>;
  loadList: (data: Record<string, unknown>) => void;
  list: Record<string, unknown>[];
  total: number;
  page: number;
  loading: boolean;
} => {
  const dispatch = useCustomDispatch();
  const providerName = _get(options, 'providerName', 'general');
  const { list, total, loading, page } = useAppSelector((state) => state[providerName]);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    if (action) {
      dispatch(action(params));
    }
  }, [action, params]);

  const loadList = (data: Partial<Params> = {}) => {
    let params: Params = {
      page: 1,
      limit: 10,
      ...data,
    };

    params = _pickBy(params, _identity) as Params;
    setParams(params);
  };

  return {
    params,
    loadList,
    list,
    total,
    loading,
    page,
  };
};

export default useFetchListNotChangeSearchParam;
