import { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';

// hooks
import { useMutate, useFetchDetail } from 'hooks';
import Helper from 'utils/helpers';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import './style.less';
import '../../skills/skills-style.less';
import TextArea from 'components/Form/TextArea';
import introductionsQuery from 'models/introductions/query';
import { IUpdateIntroduction } from 'models/introductions/interface';
import FutureSchema from './validate';

type IFutureGoal = InferType<typeof FutureSchema>;

const FutureGoalForm: React.FC<{
  initialValue?: IUpdateIntroduction;
}> = ({ initialValue }) => {
  const { control, handleSubmit, reset } = useForm<IFutureGoal>({
    resolver: yupResolver(FutureSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();

  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });

  const { mutateAsync: updateFutureGoal, isLoading: loadingUpdate } =
    useMutate<IUpdateIntroduction>(introductionsQuery.updateSelfIntroduction);

  useEffect(() => {
    if (initialValue) {
      reset({
        ...initialValue,
      });
    }
  }, [initialValue]);

  const handleSubmitFutureGoal = (values) => {
    updateFutureGoal(
      {
        futureGoal: values?.futureGoal,
      },
      {
        onSuccess: () => {
          Helper.toasts('', 'ユーザー情報の更新成功', 'success');
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );
  };

  return (
    <form
      className="skills-form-container future-goal-form-container"
      onSubmit={handleSubmit(handleSubmitFutureGoal)}
    >
      <Spin spinning={loadingUpdate}>
        <div className="future-goal-form-block">
          <div className="row-block">
            <TextArea
              name="futureGoal"
              control={control}
              label={t('introductions:futureGoal')}
              placeholder="入力してください"
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={false}
              colLabel={12}
              colWrapper={24}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextIntroductionPage.futureGoal')}
            />
          </div>

          <div className="submit-btn-wrapper">
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingUpdate}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default FutureGoalForm;
