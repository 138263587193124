const preferenceQuery = {
  orderList: {
    apiUrl: '/v1/user/order/list',
    queryKey: ['public', 'orderList'],
    customParams: {
      perPage: 999,
    },
    staleTime: Infinity,
  },
  favoriteList: {
    apiUrl: '/v1/user/order/favorite-orders',
    queryKey: ['public', 'favoriteList'],
    customParams: {
      perPage: 10,
    },
    staleTime: Infinity,
  },
  saveFavoriteOrders: {
    apiUrl: '/v1/user/order/favorite-orders',
  },
};
export default preferenceQuery;
