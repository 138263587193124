import { Space, Button, List, Typography, Col, Row } from 'antd';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { IUpdateTechnologies, ITechnologies } from 'models/work-environment/interface';
import { RADIO_CHOOSE } from 'utils/constants';

const { Text } = Typography;

interface PropsHeader {
  data: Array<ITechnologies>;
  className?: string;
  initLoading?: boolean;
  handleEdit: (objectItem: IUpdateTechnologies) => void;
  handleDelete: (objectId: string | any) => void;
}

const TechnologiesList: React.FC<PropsHeader> = ({
  data,
  className,
  initLoading,
  handleEdit,
  handleDelete,
}) => {
  return (
    <List
      className={`skills-list-container skills-list ${className || ''}`}
      itemLayout="horizontal"
      dataSource={data?.slice(0, 20)}
      loading={initLoading}
      renderItem={(item: IUpdateTechnologies) => (
        <List.Item
          actions={[
            <Button
              className="btn-delete"
              shape="circle"
              icon={<CloseRoundedIcon />}
              onClick={() => handleDelete(_get(item, '_id'))}
            />,
            <Button
              className="btn-edit"
              shape="circle"
              icon={<CreateRoundedIcon />}
              onClick={() => handleEdit(item)}
            />,
          ]}
          key={item._id}
        >
          <Space className="content-item" direction="vertical">
            <Row justify="start">
              <Col span={8}>
                <Text className="types">
                  {String(
                    _get(item, 'usedTechnologyCategory.name') === '開発言語' ||
                      _get(item, 'usedTechnologyCategory.name') === 'フレームワーク'
                      ? '開発言語・フレームワーク'
                      : _get(
                          item,
                          'usedTechnologyCategory.name',
                          _get(item, 'otherUsedTechnologyName'),
                        ),
                  )}
                </Text>
              </Col>
              <Col span={6}>
                <Text className="types">
                  {String(
                    _get(item, 'usedTechnologyName.name', _get(item, 'otherUsedTechnologyName')),
                  )}
                </Text>
              </Col>
              <Col span={4}>
                <Text className="types choose">
                  {_get(
                    _find(RADIO_CHOOSE, function (o) {
                      return o.value === _get(item, 'choose');
                    }),
                    'label',
                  )}
                </Text>
              </Col>
            </Row>
          </Space>
        </List.Item>
      )}
    />
  );
};

export default TechnologiesList;
