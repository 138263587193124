/* eslint-disable max-len */
import { useEffect } from 'react';
import { Row, Button, Spin } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useMutate } from 'hooks';
import HeaderComponent from 'components/Header';
import GreenBox from 'components/GreenBox';
import './styles.less';
import { ICheckExpiredLinkPayload, IUpdateNewPassword } from 'models/auth/interface';
import authQuery from 'models/auth/query';
import Helper from 'utils/helpers';
import { TextInput } from 'components/Form';
import ResetPasswordSchema from './validate';
type ResetPasswordsFormValues = InferType<typeof ResetPasswordSchema>;

const ResetPasswordPage = (): JSX.Element => {
  const { push } = useHistory();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ResetPasswordsFormValues>({
    resolver: yupResolver(ResetPasswordSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { mutateAsync: checkExpiredLink, isError } = useMutate<ICheckExpiredLinkPayload>(
    authQuery.checkExpiredLink,
  );
  const { mutateAsync: updateNewPassword, isLoading: isUpdateLoading } =
    useMutate<IUpdateNewPassword>(authQuery.updateNewPassword);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token') as string;

  if (Helper.getAuthToken()) {
    push('/dashboard');
  }

  useEffect(() => {
    checkExpiredLink(
      { token: token },
      {
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );
  }, []);

  const handleUpdateNewPassword = (values) => {
    updateNewPassword(
      {
        token: token,
        newPassword: values?.password,
        confirmNewPassword: values?.password,
      },
      {
        onSuccess: () => {
          Helper.toasts('', 'パスワードが再設定されました', 'success');
          push('/login');
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );
  };

  return (
    <div>
      <HeaderComponent hasLoginButton />
      <Spin spinning={isUpdateLoading}>
        <div className="reset-password-wrapper">
          <GreenBox title="パスワードの再設定">
            <form onSubmit={handleSubmit(handleUpdateNewPassword)}>
              <div className="email-input-wrapper">
                <Row justify="center">
                  <TextInput
                    noLabel={true}
                    name="password"
                    control={control}
                    required
                    inputType="password"
                    placeholder="パスワード再入力"
                    style={{ width: 343 }}
                    disabled={isError}
                    className="size-s"
                  />
                </Row>
              </div>
              <Row justify="center">
                <Button
                  className="reset-password-btn second-color"
                  htmlType="submit"
                  type="primary"
                  disabled={!isValid}
                >
                  再設定する
                </Button>
              </Row>
            </form>
          </GreenBox>
        </div>
      </Spin>
    </div>
  );
};

export default ResetPasswordPage;
