/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import RadioGroup from 'components/Form/RadioGroup';
import { RADIO_CHOOSE } from 'utils/constants';

// hooks
import { IWorkEnvironment } from 'models/work-environment/interface';
import { useMutate } from 'hooks';
import workEnvironmentQuery from 'models/work-environment/query';
import Helper from 'utils/helpers';

import FormSchema from './validate';
import '../workEnvironment-style.less';

type FormValues = InferType<typeof FormSchema>;

const DesiredProjectForm: React.FC<{
  dataSource: any;
  updateData?: any;
}> = ({ dataSource, updateData }) => {
  // state
  const [initialForm, setInitialForm] = useState({} || []);

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialForm,
  });
  const { t } = useTranslation();

  const { mutateAsync: update, isLoading } = useMutate<IWorkEnvironment>(
    workEnvironmentQuery.updateWorkEnvironment,
  );

  useEffect(() => {
    setInitialForm(dataSource);
  }, [dataSource]);

  const handleFormSubmit = (values: FormValues) => {
    const submitValues: any[] = [];
    _isArray(initialForm) &&
      initialForm.map((itemHasChoose) => {
        if (!_isEmpty(itemHasChoose.choose))
          submitValues.push({
            _id: itemHasChoose._id,
            choose: itemHasChoose.choose,
          });
      });

    update(
      {
        desiredProjectFeatures: submitValues,
      },
      {
        onSuccess: (data) => {
          Helper.toasts('', t('skills:createSuccess'), 'success');
          updateData();
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );
  };

  const handleChangeRadio = (event) => {
    const updatedItem = _isArray(initialForm)
      ? initialForm.map((item) =>
          item._id === event.target.name ? { ...item, choose: event.target.value } : item,
        )
      : [];
    setInitialForm(updatedItem);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} className="desired-project-wrapper">
      <Space className="header-form" style={{ width: '100%' }} size={21}>
        <div className="header-form__title">
          <Typography.Text>プロジェクトの特徴</Typography.Text>
        </div>
        <div className="header-form__value">
          <Typography.Text>NG</Typography.Text>
          <Typography.Text>OK</Typography.Text>
          <Typography.Text>優先</Typography.Text>
        </div>
      </Space>
      <Space direction="vertical" style={{ width: '100%' }}>
        <form
          className="desired-role-form-container details-form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className="content-details-form">
            {initialForm &&
              _isArray(initialForm) &&
              initialForm.map((item) => (
                <Space
                  className="content-form"
                  style={{ width: 'calc(100% - 50px)' }}
                  size={0}
                  direction="vertical"
                  key={item._id}
                >
                  <Space className="item_content-form" style={{ width: '100%' }} size={0}>
                    <Space className="content-form__title" style={{ width: '100%' }} size={0}>
                      <Typography.Text>{_get(item, 'name')}</Typography.Text>
                    </Space>
                    <Space className="content-form__value" style={{ width: '100%' }} size={0}>
                      <RadioGroup
                        name={_get(item, '_id')}
                        noLabel={true}
                        label={_get(item, 'name')}
                        control={control}
                        className="not-show-value"
                        value={!_isEmpty(_get(item, 'choose')) ? _get(item, 'choose') : 'not_good'}
                        onChange={handleChangeRadio}
                        options={RADIO_CHOOSE}
                      />
                    </Space>
                  </Space>
                </Space>
              ))}
          </div>

          <div className="submit-btn-wrapper">
            <Button htmlType="submit" className="second-color" type="primary" loading={isLoading}>
              {t('button:saveButton')}
            </Button>
          </div>
        </form>
      </Space>
    </Space>
  );
};

export default DesiredProjectForm;
