import React from 'react';
import Header from 'components/Header';
import notFoundImage from 'assets/images/404.png';
import GreenBox from 'components/GreenBox';

import './styles.less';

const NotFoundPage: React.FC = () => {
  return (
    <>
      <Header hasLoginButton hasRegisterButton />
      <div className="not-found-wrapper">
        <img src={notFoundImage} className="not-fount-img" alt="" />
        <GreenBox title="404">
          <div className="error-page">ERROR PAGE</div>
          <div className="content"> お探しのページは削除または移動された可能性があります。</div>
        </GreenBox>
      </div>
    </>
  );
};

export default NotFoundPage;
