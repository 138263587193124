import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from 'components/Form';
import Helper from 'utils/helpers';
import { useAppDispatch } from 'store';
// hooks
import { useMutate } from 'hooks';
import authQuery from 'models/auth/query';
import queryClient from 'utils/queryClient';
import './style.less';
import { setFormModal, setConfirmModal } from 'providers/GeneralProvider/slice';
import ChangePasswordsSchema from './validate';

type ChangePasswordsFormValues = InferType<typeof ChangePasswordsSchema>;

const ChangePasswordsForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ChangePasswordsFormValues>({
    resolver: yupResolver(ChangePasswordsSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  // APIs
  const { mutateAsync: changePasswords } = useMutate<{ oldPassword: string; newPassword: string }>(
    authQuery.changePasswords,
  );
  const { mutateAsync: logout } = useMutate(authQuery.logout);

  const changeSubmit = (values: ChangePasswordsFormValues) =>
    changePasswords(
      {
        oldPassword: values?.passwordOld,
        newPassword: values?.passwordNew,
      },
      {
        onSuccess: () => {
          dispatch(
            setConfirmModal({
              visible: true,
              data: {
                title: t('changePasswordModal:successChange'),
                content: t('changePasswordModal:contentSuccess'),
                className: 'hide-close-button',
                onOk: () => {
                  setTimeout(() => {
                    Helper.removeAuthToken();
                    Helper.removeChangePassowrd();
                  });
                  logout(null);
                  queryClient.invalidateQueries(['currentUser'], {
                    refetchType: 'none',
                  });
                  setTimeout(() => queryClient.resetQueries(['currentUser']));
                  history.push('/login');
                },
                onCancel: () => {
                  setTimeout(() => {
                    Helper.removeAuthToken();
                    Helper.removeChangePassowrd();
                  });
                  logout(null);
                  queryClient.invalidateQueries(['currentUser'], {
                    refetchType: 'none',
                  });
                  setTimeout(() => queryClient.resetQueries(['currentUser']));
                  history.push('/login');
                },
              },
            }),
          );
          dispatch(
            setFormModal({
              key: 'changePasswords',
              isOpen: false,
              data: {},
            }),
          );
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );

  return (
    <form className="change-passwords-form-container" onSubmit={handleSubmit(changeSubmit)}>
      <div className="change-passwords-form-block">
        <div className="w-343">
          <TextInput
            noLabel={true}
            name="passwordOld"
            control={control}
            required
            inputType="password"
            placeholder={t('placeholder:fieldInputOldPwd')}
          />
        </div>
        <div className="mt-28 w-343">
          <TextInput
            noLabel={true}
            name="passwordNew"
            control={control}
            required
            inputType="password"
            placeholder={t('placeholder:fieldInputNewPwd')}
          />
        </div>
        <div className="mt-28 submit-btn-wrapper">
          <Button
            htmlType="submit"
            className="change-pwd-btn second-color"
            type="primary"
            disabled={!isValid}
          >
            {t('button:changeButton')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordsForm;
