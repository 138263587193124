import moment from 'moment';
import { object, string } from 'yup';

const KnowledgeCertificationSchema = object({
  certificateItem: string().required('資格・免許名を選択してください。'),
  acquisitionAt: string().test('acquisitionAt', '正しい日付を入力してください。', (date) => {
    if (date) {
      if (moment(date, 'YYYY/MM', true).year() === moment().year()) {
        return moment(date, 'YYYY/MM', true).month() <= moment().month();
      } else {
        return (
          moment(date, 'YYYY/MM', true).isValid() &&
          moment(date, 'YYYY/MM', true).year() <= moment().year() &&
          moment(date, 'YYYY/MM', true).year() >= 1000
        );
      }
    }
    return true;
  }),
  score: string()
    .trim()
    .test('test_greater_than_0', '', function (value) {
      if (value && /[\uFF10-\uFF19]/.test(value) === true) {
        return this.createError({ message: '半角数字を入力して下さい。' });
      }
      if (value && isNaN(Number(value))) {
        return this.createError({ message: '数字を入力してください。' });
      }
      if (value && Number(value) <= 0) {
        return this.createError({ message: 'スコアを負にすることはできません' });
      }
      return true;
    }),
});

export default KnowledgeCertificationSchema;
