import { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';

// hooks
import Helper from 'utils/helpers';
import { useMutate, useFetchDetail } from 'hooks';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import '../../skills/skills-style.less';
import './style.less';
import TextArea from 'components/Form/TextArea';
import { IUpdateIntroduction } from 'models/introductions/interface';
import introductionsQuery from 'models/introductions/query';
import ImportantFormSchema from './validate';

type ICertifications = InferType<typeof ImportantFormSchema>;

const ImportantThingInWorkForm: React.FC<{
  initialValue?: IUpdateIntroduction;
}> = ({ initialValue }) => {
  const { control, handleSubmit, reset } = useForm<ICertifications>({
    resolver: yupResolver(ImportantFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();
  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });

  const { mutateAsync: updateImportantWork, isLoading: loadingUpdate } =
    useMutate<IUpdateIntroduction>(introductionsQuery.updateSelfIntroduction);

  useEffect(() => {
    if (initialValue) {
      reset({
        ...initialValue,
      });
    }
  }, [initialValue]);

  const handleSubmitImportantWork = (values) => {
    updateImportantWork(
      {
        importantThingInWork: values?.importantThingInWork,
      },
      {
        onSuccess: () => {
          Helper.toasts('', 'ユーザー情報の更新成功', 'success');
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );
  };

  return (
    <form
      className="skills-form-container important-work-form-container"
      onSubmit={handleSubmit(handleSubmitImportantWork)}
    >
      <Spin spinning={loadingUpdate}>
        <div className="certification-form-block">
          <div className="row-block full-row">
            <TextArea
              name="importantThingInWork"
              control={control}
              label={t('introductions:importantThingInWork')}
              placeholder="入力してください"
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={false}
              colLabel={24}
              colWrapper={24}
              formLayout="vertical"
              helperText={_get(
                dataHelpText,
                'value.formHelpTextIntroductionPage.importantThingInWork',
              )}
            />
          </div>

          <div className="submit-btn-wrapper">
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingUpdate}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default ImportantThingInWorkForm;
