import { PageContainer } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { Space, List, Typography, Spin } from 'antd';
import moment from 'moment';
import useFetchList from 'hooks/useList';
import settingQuery from 'models/setting/query';
import { IRecommendation } from 'models/setting/interface';
import emptyIcon from 'assets/images/empty-recommendation.svg';
import './styles.less';

const { Text, Paragraph } = Typography;

const RecommendationManagementPage = (): JSX.Element => {
  const { t } = useTranslation();
  const header = {
    title: t('menuBar:recommendation'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:recommendation')}`,
        },
      ],
    },
  };
  // get information
  const { list: data, isLoading } = useFetchList<IRecommendation>(settingQuery.recommendationList);

  if (isLoading) {
    return <Spin spinning={true} />;
  }

  return (
    <PageContainer header={header}>
      {data.length === 0 && (
        <div className="empty-list">
          <Space direction="vertical" align="center">
            <img src={emptyIcon} alt="まだ推薦データがありません。" />
            <Text className="info">まだ推薦データがありません。</Text>
          </Space>
        </div>
      )}
      {data.length > 0 && (
        <List
          className="recommendation-list-container recommendation-list"
          itemLayout="horizontal"
          dataSource={data}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            onChange: () => {
              window.scrollTo(0, 0);
            },
          }}
          loading={isLoading}
          renderItem={(item: IRecommendation) => (
            <List.Item key={item._id}>
              <Space className="content-item" direction="vertical" size={16}>
                <Space className="content-item__info" align="start">
                  <div className="info-txt">
                    <Paragraph className="first-row">
                      <span>{item.company ? item.company : '会社名非公開'}</span>{' '}
                      <span className="symbol-txt">|</span>
                      <span>{item.name ? item.name : '氏名非公開'}</span>
                    </Paragraph>
                    <Paragraph className="second-row">
                      <span>
                        会社規模:{' '}
                        {item.numberOfEmployees.key === 9
                          ? `${item.numberOfEmployees.min}人以上`
                          : `${item.numberOfEmployees.min}-${item.numberOfEmployees.max}人`}
                      </span>
                      <span className="symbol-txt">|</span>
                      <span>役職レベル: {item.jobClassification?.ja}</span>
                      <span className="symbol-txt">|</span>
                      <span>役職: {item?.jobTitle ? item.jobTitle.toString() : '非公開'}</span>
                    </Paragraph>
                  </div>
                  <Text className="createAt-txt">
                    {moment(item.createdAt).format('YYYY年MM月DD日')}
                  </Text>
                </Space>
                <div className="content-item__recommendation">{item.recommendation}</div>
              </Space>
            </List.Item>
          )}
        />
      )}
    </PageContainer>
  );
};

export default RecommendationManagementPage;
