const TRANSLATIONS_JP = {
  global: {
    noData: 'No data',
    yes: 'はい',
    no: 'いいえ',
  },
  login: {
    login: 'サインイン',
    email: 'メールアドレス',
    password: 'パスワード',
  },
  menuBar: {
    dashboard: 'ホーム',
    skills: '経験・スキル',
    workEnvironment: '職場環境選好 ',
    recommendation: '推薦文',
    introduction: '自己紹介',
    achievement: '実績・評価',
    lookBack: '振り返り',
    theme: 'テーマトーク',
    users: 'ユーザー',
    settings: '設定',
    register: '新規登録',
    verifyEmail: 'Verify email',
    role: '役割',
    knowledge: 'ドメイン知識',
    technicalSkill: '技術',
    languages: '語学',
    experience: '業務経歴',
    preference: '選好表明',
  },
  actionButton: {
    addCoach: 'コーチを追加',
    uploadAndContinue: 'アップロードして続行',
    addFile: 'ファイルを追加',
    createAccounts: 'アカウントを作成する',
    replaceFile: 'ファイルを置き換える',
    cancel: 'キャンセル',
    ok: 'わかった',
    createAccount: 'アカウントを作成する',
    changePassword: 'パスワードを変更する',
    change: '変化する',
    deactivate: '非アクティブ化',
    active: 'アクティブ',
    delete: '消去',
    prev: '前へ',
    next: '次',
    showToday: '今日見せて',
    showThisWeek: '今週見せて',
    showThisMonth: '今月表示',
    showThisCycle: 'このサイクルを表示',
    view: 'ビュー',
  },
  validation: {
    required: '必須項目です',
    requireEmail: 'メールアドレスを入力してください。',
    requireName: '氏名を入力してください。',
    requirePassword: 'パスワードを入力してください。',
    requireInitial: 'イニシャルを入力してください。',
    requireContractType: '契約形態を選択してください。',
    requireAffiliateCompany: '所属会社名を入力してください。',
    requireOriginalCompany: '所属先（業務委託契約先）会社名を入力してください。',
    requireCompanyEmail: '所属会社発行メールアドレスを入力してください。',
    requireStartPeriod: '雇用開始日を指定してください。',
    requireEndPeriod: '雇用終了日を指定してください。',
    trusteeBusinessPeriodStart: '業務受託開始日を指定してください。',
    trusteeBusinessPeriodEnd: '業務受託終了日を指定してください。',
    requireInsurance: '社会保険加入状況を選択してください。',
    requireOccupation: '職種を選択してください。',
    requireNearestStation: '最寄駅を選択してください。',
    requireAddress: '住所を選択してください。',
    invalidImage: '	5MBを超える画像はアップロードできません',
    isValidPassword: 'パスワードは英大文字小文字＋数字＋記号を混ぜてください。',
    invalidEmail: '不正なメールアドレスの形式です',
    invalidWeb: '無効なURLです',
    INVALID: '無効です',
    confirmPassNotMatch: 'The confirm password does not match',
    passRule: 'アルファベットの大文字小文字を含む英数字8文字以上で設定してください',
    registeredEmail: 'このメールアドレスは登録済みです',
    alphabetRequired: 'Name may include only alphabetical characters.',
    maxCharacters: 'Max length is 10 characters',
  },
  tableForm: {
    search: '検索',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select',
    exportResultCSV: '結果のエクスポート（CSV）',
  },
  alert: {
    clear: 'Clear',
    sessionExpired: 'Session expired',
    loginRequired: 'Your session has expired. Please log in again',
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
  button: {
    goBack: 'Go Back',
    registerButton: 'Experportに登録する',
    changeButton: '変更する',
    saveButton: '保存する',
    cancelButton: 'キャンセル',
    deleteButton: '削除する',
  },
  loading: {
    loading: 'Loading',
  },
  profile: {
    fullName: '氏名',
    accountStatus: 'アカウントのステータス',
    lastLogin: '前回のログイン',
    phoneticName: 'フリガナ',
    email: 'Eメール',
    emailAddress: 'メールアドレス',
    birthday: '誕生日',
    phone: '電話番号',
    phoneNumber: '電話番号',
    lastName: '姓',
    firstName: '名',
    phoneticLastName: 'フリガナ(姓)',
    phoneticFirstName: 'フリガナ(名)',
    displayName: '表示名',
    profilePicture: 'プロフィール写真',
    gender: '性別',
    male: '男',
    female: '女性',
    unanswered: '未回答',
    registerDate: '登録日',
    region: '地域',
    prefecture: '都道府県',
    zipCode: '郵便番号',
    businessImages: 'ビジネス画像',
    profileText: 'プロフィール文章',
    businessName: '商号',
    businessNumber: '事業番号',
    addressLine1: '番地',
    municipality: '市区町村',
    addressLine2: '建物名等',
    addressPrivacySettings: 'サロン住所公開設定',
    addressPrivacy: 'プライバシーに対処する',
    mapLocation: '地図の場所',
    accountHolder: '銀行口座（口座名）',
    accountNumber: '銀行口座（口座番号）',
    accountType: '口座の種類',
    bankBookImage: '通帳画像',
    financialInstitution: '金融機関',
    financialInstitutionName: '銀行口座（金融機関名）',
    bankCode: '金融機関コード',
    branchName: '銀行口座（支店名）',
    branchCode: '支店コード',
    username: 'ユーザー名',
    password: 'パスワード',
    role: '役割',
    newPassword: '新しいパスワード',
    permissionBooking: '予約',
    permissionProvider: 'プロバイダー管理',
    permissionCustomer: '顧客管理',
    permissionService: 'サービス管理',
    description: '説明',
  },
  detail: {},
  common: {},
  action: {},
  status: {},
  title: {
    registerFormTitle: '会員登録',
    changeEmailFormTitle: 'メールアドレスの変更',
    rolesTitle: '担当可能な役割・工程',
    qualificationTitle: '保有資格',
    domainKnowledgeTitle: '知見のあるドメイン知識',
    languagesTitle: '使用可能な言語',
    technicalTitle: '使用可能な技術',
    technicalSkillPage: '技術',
    workExperienceTitle: '過去の代表的なプロジェクト',
  },
  description: {
    deleteConfirm: '選択した内容を削除しますか？',
  },
  tableColumn: {},
  columnTitle: {},
  placeholder: {
    fieldInputEmail: 'メールアドレス',
    fieldInputConfirmEmail: 'メールアドレスの再入力',
    fieldInputOldPwd: '現在のパスワード',
    fieldInputNewPwd: '新しいパスワード',
    fieldRole: '役割',
    fieldProcess: '工程',
    fieldLevel: 'レベル',
    fieldExperienceYears: '例）3.5',
    fieldDetail: '入力してください',
    fieldLicense: '選択してください',
    fieldAcquisition: 'YYYY/ＭＭ',
    fieldScore: '入力してください',
    fieldSelect: '選択して下さい',
  },
  type: {
    protect: 'Protect',
    public: 'Public',
    saving: 'Saving',
    standard: 'Standard',
  },
  menuAvatar: {
    setting: '設定',
    guide: 'ご利用ガイド',
    contact: 'お問い合わせ',
    logout: 'ログアウト',
  },
  dateViewMode: {},
  payout: {},
  service: {},
  notification: {},
  changeEmailModal: {
    requestTitle: '認証メールを送信しました',
    contentRequest:
      'ご入力いただいたメールアドレスに認証メールを送信しました。\nリンクをクリックしてメールアドレスの再設定を行ってください。',
    successChange: 'メールアドレスが変更されました',
    contentSuccess: '新しいメールアドレスで再ログインしてください.',
  },
  changePasswordModal: {
    successChange: 'パスワードが変更されました',
    contentSuccess: 'パスワードが正常に変更されました。 再ログインしてください。',
  },
  skills: {
    rolesField: '役割',
    processField: '担当工程・タスク',
    experienceYearsField: '経験年数',
    detailField: '詳細',
    createSuccess: '情報が保存されました。',
    updateSuccess: '正常に更新されました。',
    deleteSuccess: '正常に削除されました。',
    industryField: 'ドメイン知識',
    levelField: 'レベル',
    languageField: '言語',
    skillCategoryField: '技術カテゴリ',
    skillName: '技術名',
    skillLevel: '技術レベル',
    skillLevelTechnical: 'レベル',

    projectNameField: 'プロジェクト名・業務名',
    endUserField: 'エンドユーザー',
    responsibilityCompanyField: 'プロジェクト責任会社',
    periodStartField: '期間（開始）',
    periodEndField: '期間（終了）',
    roleProjectField: '役割',
    inChargeProcessField: '担当工程',
    overviewField: 'プロジェクト概要',
  },
  certifications: {
    licenseName: '資格・免許名',
    dateOfAcquisition: '取得時期',
    score: '点数',
  },
  introductions: {
    memo: '利用シーン・メッセージの相手など',
    selfIntroductionDetail: '自己紹介詳細',
    linkToAperport: 'Aperport連携に使用',
    futureGoal: '将来の目標',
    importantThingInWork: '仕事をする上で大切にしていること',
  },
  formHelpTextRolePage: {
    role:
      '業務SE：要件定義・外部設計を通してビジネス/業務の観点から画面・機能の妥当性を担保します。\n' +
      '\n' +
      'アーキテクト：非機能要件定義・方式設計を通して要求されたシステムの設計・実装品質と実現を担保します。\n' +
      '\n' +
      'プログラマー：システムの内側の観点から設計・コードの妥当性を担保します。\n' +
      '\n' +
      '品質保証エンジニア：上流から下流まで各工程の成果物の品質を担保します。\n' +
      '\n' +
      'プロジェクトマネージャー：上流から下流まで全工程で発生する問題を早期発見し解決します。\n' +
      '\n' +
      '運用保守担当：運用を見据えた設計をリードし、ビジネス観点・システム観点の運用を担当します。',
    process:
      '要件定義：ビジネス上の要求を引き出し、整理し、システム上の制約をある程度踏まえて概算見積もりが可能な状態の要件定義書を作成する。\n' +
      '\n' +
      '外部設計：ビジネス/業務の観点から画面の振る舞いと、機能（ビジネスロジック）を確定し、内部設計・実装と詳細な見積もりが可能な状態の画面設計書・機能設計書を作成する。\n' +
      '\n' +
      '方式設計：ビジネス側の要件を踏まえて、アーキテクチャやネットワーク、構成管理などシステムの設計と実装品質担保に必要なあらゆる観点を考慮し、性能やセキュリティにも配慮された方式設計書を作成する。\n' +
      '\n' +
      '内部設計：画面設計・機能設計・方式設計に基づき、システム内部の設計を具体的に行う。実装前に詳細設計書を作成することもあれば、実装後に実装意図が他人に伝わるための設計メモを残すこともある。\n' +
      '\n' +
      '実装：確定した画面設計・機能設計・方式設計または詳細設計に基づき実装を行う。単体テストの設計やテストコードの作成を実装の前に行うこともある。\n' +
      '\n' +
      '単体テスト：自身が実装を担当したモジュールに関して設計通りの振る舞い・動きをしているかを100%担保する。\n' +
      '\n' +
      '結合テスト：他のモジュールとの結合が設計通りの振る舞い・動きをしているかを100%担保する。\n' +
      '\n' +
      '総合テスト：ビジネス/業務の観点から要求・設計通りの振る舞い・動きをしているかをレアケースも含めて検証する。性能・セキュリティに関する非機能要件や、ユーザー受入テストもすべて包含する。',
    level:
      '■レベル３\n' +
      '独力で各役割が担う工程における課題の発見と解決をリードする\n' +
      'プロフェッショナルとして求められる経験の知識化や後進育成に貢献している\n' +
      '\n' +
      '業務経験年数の目安：4年以上\n' +
      '\n' +
      '■レベル２\n' +
      '要求された作業をすべて独力で遂行する\n' +
      '全ての意思決定までは行わないが、意思決定に必要な要素を提示し、一部の意思決定には参加する\n' +
      '\n' +
      '業務経験年数の目安：2年以上\n' +
      '\n' +
      '■レベル１\n' +
      '上位者・経験者の指導のもとに要求された作業を担当する\n' +
      '\n' +
      '業務経年年数の目安；2年未満',
    detail:
      '選択した役割と工程に関して、どのような経験をしたのかを総括してください。\n' +
      '\n' +
      '読み手であるプロジェクト責任者が、あなたがプロジェクトでどのようにその役割を担って活躍できそうかをイメージできるように書いてください。\n' +
      '\n' +
      '選択したレベルに見合う実力を説明できるストーリーを1-3つ示されていると説得力が増します。',
  },
  formHelpTextKnowledgePage: {
    knowledge:
      'ドメイン知識は業務システムと組込みシステムを想定しています。\n' +
      '\n' +
      '業務システムの場合、販売管理システムや会計システムのようなすべての業種で共通のドメイン知識もあれば、製造業・金融・医療など特定の業界のみで必要なドメイン知識もあります。\n' +
      '\n' +
      '組込みシステムの場合、車載や医療機器などマイクロコンピューターを搭載した個々の機械や装置毎のシステムに関するドメイン知識を指します。',
    level:
      '■レベル３\n' +
      '独力で各ドメイン知識における課題の発見と解決をリードする\n' +
      '（ユーザーと同じ目線で特定業界のビジネスやオペレーションの課題や改善点を提示できる）\n' +
      'プロフェッショナルとして求められる経験の知識化や後進育成に貢献している\n' +
      '\n' +
      '業務経験年数の目安：4年以上\n' +
      '\n' +
      '■レベル２\n' +
      '要求された作業をすべて独力で遂行する\n' +
      '全ての意思決定までは行わないが、意思決定に必要な要素を提示し、一部の意思決定には参加する\n' +
      '\n' +
      '業務経験年数の目安：2年以上\n' +
      '\n' +
      '■レベル１\n' +
      '上位者・経験者の指導のもとに要求された作業を担当する\n' +
      '\n' +
      '業務経年年数の目安；2年未満',
    detail:
      '選択したドメイン知識に関して、どのような経験をしたのかを総括してください。\n' +
      '\n' +
      '読み手であるプロジェクト責任者が、あなたがプロジェクトでどのようにそのドメイン知識を使って活躍できそうかをイメージできるように書いてください。\n' +
      '\n' +
      '選択したレベルに見合う実力を説明できるストーリーを1-3つ示されていると説得力が増します。',
  },
  formHelpTextTechnicalPage: {
    level:
      '■レベル３\n' +
      '独力で技術的な課題の発見と解決をリードする\n' +
      '\n' +
      'プロフェッショナルとして求められる経験の知識化や後進育成に貢献している\n' +
      '\n' +
      '業務経験年数の目安：4年以上\n' +
      '\n' +
      '期待される行動の例：リファクタリングができる、レベル１・２の期待行動を高品質で実施し、他人の成果物に対して改善事項の指摘ができる。\n' +
      '\n' +
      '■レベル２\n' +
      '要求された作業をすべて独力で遂行する\n' +
      '全ての意思決定までは行わないが、意思決定に必要な要素を提示し、一部の意思決定には参加する\n' +
      '\n' +
      '業務経験年数の目安：2年以上\n' +
      '\n' +
      '期待される行動の例：\n' +
      '\n' +
      '• 既存コードの分析ができる\n' +
      '\n' +
      '• 既存コードの複雑な仕組みを他人に説明できる\n' +
      '\n' +
      '• デバッグできる（設計の欠陥を検出し、修正する）\n' +
      '\n' +
      '• 既存の仕組みを新しい大きな問題の構成要素として使用する\n' +
      '\n' +
      '• 結合する他のシステムとの関係性を理解する\n' +
      '\n' +
      '• 設計する\n' +
      '\n' +
      '• 概念図やフロー図など図式化する\n' +
      '\n' +
      '■レベル１\n' +
      '上位者・経験者の指導のもとに要求された作業を担当する\n' +
      '業務経年年数の目安；2年未満\n' +
      '\n' +
      '期待される行動の例：\n' +
      '\n' +
      '• 基本的な知識や各技術に特有の用語・概念を知っている\n' +
      '\n' +
      '• 基本的な操作や動作確認ができる（単体テストができる）\n' +
      '\n' +
      '• 既存コードを設計通りとなるよう修正できる\n' +
      '\n' +
      '• 設計に従って０から実装できる（コードに落とし込む）\n' +
      '\n' +
      '• 既存の仕組みを同じ複雑度の問題に応用するために修正できる\n' +
      '\n' +
      '• レベル２で期待される行動を他人の指示・協力を得ながら実施できる',
    detail:
      '選択した技術に関して、どのような経験をしたのかを総括してください。\n' +
      '\n' +
      '読み手であるプロジェクト責任者が、あなたがプロジェクトでどのようにその技術を使って活躍できそうかをイメージできるように書いてください。\n' +
      '\n' +
      '選択したレベルに見合う実力を説明できるストーリーを1-3つ示されていると説得力が増します。',
  },
  formHelpTextLanguagesPage: {
    level:
      '■レベル３\n' +
      'エンドユーザーに対して一人で特定の言語で開発状況の説明や障害の原因分析結果を書面・口頭で説明できるレベル\n' +
      '\n' +
      '■レベル２\n' +
      '自らが所属するチームリーダーに対して一人で特定の言語で開発状況の説明や障害の原因分析を書面・口頭で説明し、8割以上は正確に意図を伝えられるレベル\n' +
      '\n' +
      '■レベル１\n' +
      '特定の言語に関してある程度使用することはできるが、コミュニケーション・相互理解がうまくできない可能性があるレベル',
    detail:
      '選択した言語に関して、どのような経験をしたのかを総括してください。\n' +
      '\n' +
      '読み手であるプロジェクト責任者が、あなたがプロジェクトでどのようにその言語を使って活躍できそうかをイメージできるように書いてください。\n' +
      '\n' +
      '選択したレベルに見合う実力を説明できるストーリーを1-3つ示されていると説得力が増します。',
  },
  formHelpTextWorkExperiencePage: {
    endUser:
      'システムを利用する最終発注元のユーザー会社の名前をご登録ください。\n' +
      'Aperportには連携されません。',
    responsibility:
      'システム開発を受託した元請け（プライムベンダー）の名前をご登録ください。\n' +
      'Aperportに連携されます。',
    overview:
      'プロジェクトの概要（背景・チーム人数など）や、ご自身が担当した役割・工程の中で具体的に遂行したタスクや成果物をご記入ください。\n' +
      '\n' +
      '読み手であるプロジェクト責任者が、あなたがプロジェクトで過去の経歴と同様の問題や、より高度な問題を解決して、活躍できそうかをイメージできるように書いてください。 \n' +
      '\n' +
      '記載したタスクや成果物のうち、1~3つより詳しい説明をし、複雑度や難易度が伝わると説得力が増します。',
  },
  formHelpTextIntroductionPage: {
    memo:
      '自己紹介文には３つのポイントがあります。３つのポイントを押さえて用意すると、より望ましいプロジェクトに参画する可能性があがります。\n' +
      '\n' +
      'この項目は想定する利用シーンやメッセージの相手についてのメモとしてご活用ください。\n' +
      'Aperportには連携されません。\n' +
      '\n' +
      '【自分自身】\n' +
      'あなたが仕事で担うことができる役割、技術、ドメイン知識、経歴の概要、性格、志向性（価値観）などの要約\n' +
      '\n' +
      '【読み手・自己紹介文を読む相手】\n' +
      'どのような相手を想定して、その相手が求める情報を軸に「構成」や「伝えたいメッセージ」や「伝え方」を変更する\n' +
      '・理想の職場環境を具体的にイメージし、そのプロジェクト責任者を相手とする\n' +
      '・具体的なプロジェクト情報がある場合に、そのプロジェクト責任者がイメージしやすい内容を整理する\n' +
      '\n' +
      '【文脈】\n' +
      'どのような利用シーン（文脈）で自己紹介するのかに応じて、「構成」や「伝えたいメッセージ」や「伝え方」を変更する\n' +
      '・文字で伝える場面（経歴書、メール、ウェブなど）\n' +
      '・口頭で伝える場面（顔合わせ、面談など）',
    selfIntroductionDetail:
      'あなたが仕事で担うことができる役割、技術、ドメイン知識、経歴の概要、性格、志向性（価値観）などの要約をご記入ください。\n' +
      '（性格や志向性（価値観）は自ら伝えずに、他の人に伝えてもらう形でも構いません。）\n' +
      '\n' +
      '読み手であるプロジェクト責任者が、あなたの人物像をイメージし、より詳細な情報を読まなくとも、プロジェクトで活躍できそうかをイメージできるように書いてください。',
    linkToAperport:
      '自己紹介文は複数登録することができます。\n' +
      '\n' +
      '複数登録した自己紹介文のうち、「Aperport連携に使用」のチェックをいれたもののみが、Aperportに連携されます。',
    futureGoal:
      'あなたと一緒に仕事をすることを検討している人は、あなたの個人的な目標に配慮しているかもしれません。\n' +
      '\n' +
      'プロジェクトへの参画により、個人的な目標の達成もできることを相手がイメージできれば、プロジェクトの成功可能性があがるため、相手が一緒に働きたいと思ってくれる可能性が上がります。',
    importantThingInWork:
      'あなたが仕事で大切にしたいと思っていることは、相手があなたと接するうえでも大切にしたいことです。あなたが他の人に持ってもらいたいあなた自身のイメージを意識しながら、ご自身の思いやこだわりを伝えてみましょう。',
  },
  formHelpTextWorkEnvironmentPage: {
    technicalSkillPreference:
      '「優先」が選択された場合のみAperportに連携されます。\n' +
      '「NG」「OK」を選択した場合はAperportに連携されません。',
    desired:
      '「優先」が選択された場合のみAperportに連携されます。\n' +
      '「NG」「OK」を選択した場合はAperportに連携されません。',
    choiceOfWorkingHours:
      '朝に多くの仕事をやりたい人と、夜に多くの仕事をやりたい人では、最適な勤務時間が異なります。\n' +
      '\n' +
      'また、プライベートの都合上、業務開始時間を遅らせたり、早退が必要な場合もあります。\n' +
      '\n' +
      'これらの事項は相談結果次第になりますが、事前に伝えることでより働きやすい環境を実現できる可能性があります。',
    choiceOfWorkingPlaces:
      '仕事で高いパフォーマンスを出すためにオフィス環境が影響する場合があります。\n' +
      'オフィス環境はあなた一人で決められるものではありませんが、相談の結果、選択の余地が拡がったり、改善を促すことができる可能性があります。\n' +
      '\n' +
      'まずは、あなたのパーソナリティ（性格特性）、担当することになる仕事の複雑性、リーダー・マネージャーや他のメンバーとどのような関係性を築きたいかを整理しましょう。\n' +
      '\n' +
      'その上で、具体的に以下の内容について、あなた自身とプロジェクト全体のパフォーマンス向上のために、あなたの好みを伝えましょう。\n' +
      '\n' +
      '-リモートワーク・出勤に関する要望\n' +
      '（完全リモート希望、リモート中心、出勤中心、完全出勤など）\n' +
      '-パーソナルスペースの確保（パーティションの設置有無）\n' +
      '-雑音・無音を避けたい\n' +
      '-照明が暗いオフィスを避けたい\n' +
      '-コミュニケーション手段（チャットツールの使用、談話スペースなど）',
  },
  formHelpTextProfilePage: {
    emailLabel:
      'Experportでは会社ではなく個人がすべての登録内容を管理することを想定しています。\n' +
      'この点を踏まえたうえで、個人のメールアドレスもしくは所属会社等が発行したメールアドレスのいずれかにつき、利便性を踏まえつつ、適宜ご利用ください。',
    avatarColor:
      'Experport上のアイコンで表示されます。\n' +
      'また、Aperportに情報連携をした後はAperport上でもパーソナルカラーが表示されます。',
    companyLinked:
      'Aperportを使用している所属会社より招待を受けた場合に、Experportの情報をAperportに連携する機能が使えるようになります。',
    username:
      'Aperportには連携されません。\n' +
      'Experport上で表示される名前です。\n' +
      '未入力の場合は、画面右上に氏名が表示されます。',
    initial: 'Aperportに連携されます。',
    contractType:
      '無期雇用契約（定年までまたは無期限の雇用契約） \n有期雇用契約（期間の定めのある雇用契約） \n業務委託契約（個人事業主・フリーランスの準委任契約・請負契約）' +
      '\n Aperportに連携されます。 無期雇用契約と有期雇用契約はいずれも「雇用契約」としてAperportで表示され、業務委託契約はそのまま「業務委託契約」として表示されます。',
    companyName:
      '雇用契約（無期雇用契約または有期雇用契約）を交わしている会社の名前をご登録ください。\n Aperportに連携されます。',
    outsourcing:
      '業務委託契約（請負契約または準委任契約）を交わしている会社の名前をご登録ください。 \n Aperportに連携されます。',
    companyMailaddress:
      '雇用契約を交わしている会社から発行されたメールアドレスをご登録ください。\n個人事業主の場合は、業務委託契約を交わしている会社から発行されたメールアドレスか、個人のメールアドレスをご登録ください。' +
      '\n Aperportには連携されません。',
  },
  connectionAP: {
    unlinkSuccess: '会社との連携は解除されました。',
    linkedSuccess: 'Aperportとの連携が完了しました',
  },
};

export default TRANSLATIONS_JP;
