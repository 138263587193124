/* eslint-disable @typescript-eslint/no-empty-function */
import { Modal } from 'antd';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useAppDispatch, useAppSelector } from 'store';
import { setConfirmModal, resetConfirmModal } from 'providers/GeneralProvider/slice';
import i18n from 'i18n';
import './style.less';

interface ConfirmModalData {
  visible: boolean;
  data: {
    title?: string;
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onCancel?: () => void;
    closeAfterConfirm?: boolean;
    content?: React.ReactNode;
    className?: string;
    [key: string]: React.ReactNode | (() => void) | string | undefined;
  };
}

const ConfirmModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector((state) => state.general.confirmModal) as ConfirmModalData;
  const { visible, data = {} } = modalData;
  const {
    title,
    okText = i18n.t('global:yes'),
    cancelText = i18n.t('global:no'),
    onOk = () => {},
    onCancel = () => {},
    closeAfterConfirm = true,
    content,
    className,
    ...props
  } = data;

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    dispatch(setConfirmModal({ visible: false }));
  };

  const handleConfirm = () => {
    if (typeof onOk === 'function') {
      onOk();
    }
    if (closeAfterConfirm) {
      // Close popup first
      dispatch(setConfirmModal({ visible: false }));
    }
  };

  return (
    <Modal
      className={`confirm-modal ${className || ''}`}
      title={title}
      open={visible}
      afterClose={() => dispatch(resetConfirmModal())} // Remove popup data when popup is close
      onCancel={handleCancel}
      onOk={handleConfirm}
      cancelText={cancelText}
      okText={okText}
      centered
      zIndex={100}
      closeIcon={<AddCircleRoundedIcon />}
      width={658}
      maskClosable={false}
      {...props}
    >
      {content && <div>{content}</div>}
    </Modal>
  );
};

export default ConfirmModal;
