import { object, string } from 'yup';
import { Regex } from 'utils/constants';

const PreCheckEmailSchema = object({
  email: string()
    .transform((value) => value.trim())
    .required('メールアドレスを入力してください。')
    .matches(Regex.EMAIL, '不正なメールアドレスの形式です。')
    .max(254, '入力文字数が上限に達しました。'),
});

export default PreCheckEmailSchema;
