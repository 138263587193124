import { useEffect } from 'react';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from 'components/Form';
import Helper from 'utils/helpers';
import { useAppDispatch } from 'store';
// hooks
import { useMutate } from 'hooks';
import authQuery from 'models/auth/query';
import './style.less';
import { setFormModal, setConfirmModal } from 'providers/GeneralProvider/slice';
import ChangeEmailSchema from './validate';

type ChangeEmailFormValues = InferType<typeof ChangeEmailSchema>;

const ChangeEmailForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitSuccessful },
    reset,
  } = useForm<ChangeEmailFormValues>({
    resolver: yupResolver(ChangeEmailSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // APIs
  const { mutateAsync: requestChangeEmail } = useMutate<{ email: string }>(
    authQuery.requestChangeEmail,
  );

  const changeSubmit = (values: ChangeEmailFormValues) =>
    requestChangeEmail(
      { email: values?.email },
      {
        onSuccess: () => {
          dispatch(
            setConfirmModal({
              visible: true,
              data: {
                className: 'hide-close-button success-request-change-email',
                title: t('changeEmailModal:requestTitle'),
                content: t('changeEmailModal:contentRequest'),
                centered: true,
                zIndex: 9999,
                onOk: () => {
                  dispatch(
                    setFormModal({
                      key: 'requestChangeEmail',
                      isOpen: false,
                      data: {},
                    }),
                  );
                },
              },
            }),
          );
          dispatch(
            setFormModal({
              key: 'requestChangeEmail',
              isOpen: false,
              data: {},
            }),
          );
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message', ''), 'error');
        },
      },
    );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ email: '', confirmEmail: '' });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <>
      <form className="change-email-form-container" onSubmit={handleSubmit(changeSubmit)}>
        <div className="change-email-form-block">
          <div className="w-343">
            <TextInput
              name="email"
              noLabel={true}
              control={control}
              required
              placeholder={t('placeholder:fieldInputEmail')}
            />
          </div>
          <div className="mt-28 w-343">
            <TextInput
              name="confirmEmail"
              noLabel={true}
              control={control}
              required
              placeholder={t('placeholder:fieldInputConfirmEmail')}
            />
          </div>
          <div className="mt-28 submit-btn-wrapper">
            <Button
              htmlType="submit"
              className="change-email-btn second-color"
              type="primary"
              disabled={!isValid}
            >
              {t('button:changeButton')}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChangeEmailForm;
