import { put, takeEvery, takeLeading, delay } from 'redux-saga/effects';
import _pick from 'lodash/pick';

import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import Helper from 'utils/helpers';
import { callApi } from 'providers/GeneralProvider/saga';
import {
  loginRequest,
  loginSuccess,
  loginError,
  logoutRequest,
  logoutError,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserError,
  changePasswordRequest,
  changePasswordSuccess,
  preCheckEmailSignUpRequest,
  preCheckEmailSignUpSuccess,
  resetPasswordSuccess,
  resetPasswordRequest,
  resetPasswordError,
  checkExpiredLinkRequest,
  updateNewPasswordRequest,
  checkExpiredLinkSuccess,
  updateNewPasswordSuccess,
  updateNewPasswordError,
  checkExpiredLinkError,
  preCheckEmailSignUpError,
} from 'providers/AuthProvider/slice';
import { userService } from 'services';

function* handleLogin(action) {
  try {
    // NOTE: Replace with your api and configuration

    const result = yield callApi(userService.login, action.payload);

    const { data } = result;
    if (data.accessToken) {
      Helper.storeAuthToken(data.accessToken);
    }
    yield put(loginSuccess(data));
    yield put(push('/dashboard'));
    // Helper.toasts('', message, 'success');
  } catch (error) {
    yield put(loginError());
    Helper.toasts('', error?.message, 'error');
  }
}

export function* handleLogout(): Generator {
  try {
    // Need to call api logout to delete sessionToken on server without waiting until it finishes
    // use Call of redux-saga effect, not callApi
    // call(api.put, '/account/admin/logout');
    Helper.removeAuthToken();
    window.location.reload();
  } catch (error) {
    yield put(logoutError());
  }
}

export function* getCurrentUser(): Generator {
  try {
    // TODO: Replace delay with api call and getCurrentUserSuccess with result
    const response = yield callApi(userService.getCurrentUser, {});
    yield put(getCurrentUserSuccess(response));
  } catch (error) {
    yield put(getCurrentUserError());
  }
}

export function* changePassword(action: AnyAction): Generator {
  try {
    const params = _pick(action.payload, ['client_id', 'email', 'connection']);
    const data = yield callApi(userService.changePassword, params);

    yield put(changePasswordSuccess({}));
    const message = `${data}`;
    Helper.toasts('', message, 'success');
  } catch (error) {}
}

export function* preCheckEmailSignUp(action: AnyAction): Generator {
  try {
    const params = _pick(action.payload, ['email']);
    const data = yield callApi(userService.preCheckEmail, params);

    yield put(preCheckEmailSignUpSuccess(data));
    yield put(push('/verify-email-success'));
  } catch (error) {
    yield delay(700);
    yield put(preCheckEmailSignUpError(error));
    Helper.toasts('', error.message, 'error');
  }
}
export function* resetPassword(action: AnyAction): Generator {
  try {
    const params = _pick(action.payload, ['email']);
    const data = yield callApi(userService.resetPassword, params);

    yield put(resetPasswordSuccess(data));
    yield put(push('/sent-reset-password-request'));
  } catch (error) {
    yield put(resetPasswordError(error));
    Helper.toasts('', error?.message, 'error');
  }
}
export function* checkExpiredLink(action: AnyAction): Generator {
  try {
    const params = _pick(action.payload, ['token']);
    const data = yield callApi(userService.checkExpiredLink, params);

    yield put(checkExpiredLinkSuccess(data));
  } catch (error) {
    yield put(checkExpiredLinkError(error));
    Helper.toasts('', error?.message, 'error');
  }
}
export function* updateNewPassword(action: AnyAction): Generator {
  try {
    const params = _pick(action.payload, ['token', 'newPassword', 'confirmNewPassword']);
    const data = yield callApi(userService.changePassword, params);
    Helper.toasts('', '正常に更新されました', 'success');

    yield put(updateNewPasswordSuccess(data));
    yield delay(1200);
    yield put(push('/login'));
  } catch (error) {
    yield put(updateNewPasswordError(error));
    Helper.toasts('', error?.message, 'error');
  }
}

export default function* watchAuth(): Generator {
  yield takeLeading(loginRequest.type, handleLogin);
  yield takeLeading(logoutRequest.type, handleLogout);
  yield takeEvery(getCurrentUserRequest.type, getCurrentUser);
  yield takeLeading(changePasswordRequest.type, changePassword);
  yield takeLeading(preCheckEmailSignUpRequest.type, preCheckEmailSignUp);
  yield takeLeading(resetPasswordRequest.type, resetPassword);
  yield takeLeading(checkExpiredLinkRequest.type, checkExpiredLink);
  yield takeLeading(updateNewPasswordRequest.type, updateNewPassword);
}
