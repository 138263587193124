import moment from 'moment';
import { array, object, string } from 'yup';
import { Regex } from 'utils/constants';

const RegisterSchema = object({
  email: string().trim(),
  password: string()
    .trim()
    .required('パスワードを入力してください。')
    .min(15, 'パスワードは15文字以上で入力してください。')
    .matches(Regex.PASSWORD, 'パスワードは英大文字小文字数字記号を混ぜてください。')
    .max(128, '入力文字数が上限に達しました。'),

  fullName: string()
    .trim()
    .required('氏名を入力してください。')
    .max(100, '入力文字数が上限に達しました。'),
  username: string().trim().max(100, '入力文字数が上限に達しました。'),
  phonetic: string()
    .trim()
    .max(100, '入力文字数が上限に達しました。')
    .test('phonetic', 'ヨミガナを記入してください。', (phonetic) => {
      if (phonetic) {
        return Regex.KATAKANA.test(phonetic);
      }
      return true;
    }),
  initial: string()
    .trim()
    .required('イニシャルを入力してください。')
    .max(10, '入力文字数が上限に達しました。'),
  contractType: string().required(' 契約形態を選択してください。'),
  affiliatedCompanyName: string()
    .trim()
    .max(100, '入力文字数が上限に達しました。')
    .when('contractType', {
      is: 'OUTSOURCING',
      then: string().optional(),
      otherwise: string().required('所属会社名を入力してください。'),
    }),
  buildingName: string().optional().trim().max(100, '入力文字数が上限に達しました。'),
  companyNameOriginal: string()
    .trim()
    .max(100, '入力文字数が上限に達しました。')
    .when('contractType', {
      is: 'OUTSOURCING',
      then: string().required('所属先（業務委託契約先）会社名を入力してください。'),
    }),
  companyMail: string()
    .trim()
    .max(254, '入力文字数が上限に達しました。')
    .required('所属会社発行メールアドレスを入力してください。')
    .matches(Regex.EMAIL, '不正なメールアドレスの形式です。'),
  employmentPeriodStartTime: string().when('contractType', {
    is: (contractType: string) => {
      if (contractType === 'FREELANCE' || contractType === 'EMPLOYMENT') {
        return true;
      } else return false;
    },
    then: string()
      .required('雇用開始日を指定してください。')
      .test('employmentPeriodStartTime', '雇用開始日を指定してください。', (date) => {
        return moment(date, 'YYYY/MM/DD', true).isValid() && moment(date).year() >= 1900;
      })
      .test(
        'employmentPeriodStartTime',
        '期間指定開始日または終了日の日付範囲が正しくありません。',
        function (endTime, ref) {
          if (ref.parent.employmentPeriodEndTime) {
            return moment(endTime, 'YYYY/MM/DD').isBefore(
              moment(ref.parent.employmentPeriodEndTime, 'YYYY/MM/DD'),
            );
          }
          return true;
        },
      ),
  }),

  employmentPeriodEndTime: string().when('contractType', {
    is: 'EMPLOYMENT',
    then: string()
      .required('雇用終了日を指定してください。')
      .test('employmentPeriodEndTime', '雇用終了日を指定してください。', (date) => {
        return moment(date, 'YYYY/MM/DD', true).isValid() && moment(date).year() >= 1970;
      })
      .test(
        'employmentPeriodEndTime',
        '期間指定開始日または終了日の日付範囲が正しくありません。',
        function (endTime, ref) {
          if (ref.parent.employmentPeriodStartTime) {
            return moment(endTime, 'YYYY/MM/DD').isAfter(
              moment(ref.parent.employmentPeriodStartTime, 'YYYY/MM/DD'),
            );
          }
          return true;
        },
      ),
    otherwise: string().optional(),
  }),

  socialInsuranceStatus: string().required('社会保険加入状況を選択してください。'),
  jobTitle: array().required('職種を選択してください。').min(1, '職種を選択してください。'),
  countryCitizenship: string().optional(),
  countryOrigin: string().optional(),
  prefecture: string().required('住所を選択してください。'),
  city: string().required('住所を選択してください。'),
  lineStation: string().required('最寄駅を選択してください。'),
  stationNearest: string().required('最寄駅を選択してください。'),
  birthday: string().test('birthday', '正しい生年月日を入力してください', (date) => {
    if (date) {
      return (
        moment(date, 'YYYY/MM/DD', true).isValid() &&
        moment(date).year() >= 1000 &&
        moment(date, 'YYYY/MM/DD', true).year() <= moment().year()
      );
    }
    return true;
  }),

  trusteeBusinessPeriodStartTime: string().when('contractType', {
    is: 'OUTSOURCING',
    then: string()
      .required('業務受託開始日を指定してください。')
      .test('trusteeBusinessPeriodStartTime', '業務受託開始日を指定してください。', (date) => {
        return moment(date, 'YYYY/MM/DD', true).isValid() && moment(date).year() >= 1900;
      })
      .test(
        'trusteeBusinessPeriodStartTime',
        '期間指定開始日または終了日の日付範囲が正しくありません。',
        function (endTime, ref) {
          if (ref.parent.trusteeBusinessPeriodEndTime) {
            return moment(endTime, 'YYYY/MM/DD').isBefore(
              moment(ref.parent.trusteeBusinessPeriodEndTime, 'YYYY/MM/DD'),
            );
          }
          return true;
        },
      ),
  }),

  trusteeBusinessPeriodEndTime: string().when('contractType', {
    is: 'OUTSOURCING',
    then: string()
      .required('業務受託終了日を指定してください。')
      .test('trusteeBusinessPeriodEndTime', '業務受託終了日を指定してください。', (date) => {
        return moment(date, 'YYYY/MM/DD', true).isValid() && moment(date).year() >= 1900;
      })
      .test(
        'trusteeBusinessPeriodEndTime',
        '期間指定開始日または終了日の日付範囲が正しくありません。',
        function (endTime, ref) {
          if (ref.parent.trusteeBusinessPeriodStartTime) {
            return moment(endTime, 'YYYY/MM/DD').isAfter(
              moment(ref.parent.trusteeBusinessPeriodStartTime, 'YYYY/MM/DD'),
            );
          }
          return true;
        },
      ),
  }),
});

export default RegisterSchema;
